
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root {
  --font-family: 'Roboto', sans-serif;
  
  /* --color-bg : #040C18;
  --color-footer : #031B34;
  --color-blog : #81AFDD;
  --color-subtext : #FF8A71; */


  
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #9397ad;
  --bs-gray-dark: #3e4265;
  --bs-gray-100: #f3f6ff;
  --bs-gray-200: #eff2fc;
  --bs-gray-300: #e2e5f1;
  --bs-gray-400: #d4d7e5;
  --bs-gray-500: #b4b7c9;
  --bs-gray-600: #9397ad;
  --bs-gray-700: #585c7b;
  --bs-gray-800: #3e4265;
  --bs-gray-900: #131022;
  --bs-primary: #6366f1;
  --bs-secondary: #eff2fc;
  --bs-success: #22c55e;
  --bs-info: #4c82f7;
  --bs-warning: #ffba08;
  --bs-danger: #ef4444;
  --bs-light: #fff;
  --bs-dark: #131022;
  --bs-primary-rgb: 99, 102, 241;
  --bs-secondary-rgb: 239, 242, 252;
  --bs-success-rgb: 34, 197, 94;
  --bs-info-rgb: 76, 130, 247;
  --bs-warning-rgb: 255, 186, 8;
  --bs-danger-rgb: 239, 68, 68;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 19, 16, 34;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 88, 92, 123;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Manrope", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #585c7b;
  --bs-body-bg: #fff;
}

