
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior:smooth;
}

body {
  background-color: var(--color-bg);
}

a{
  color: unset;
  text-decoration: none;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.gradient__bg
{

  background:-moz-radial-gradient(circle at 3% 25%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 3% 25%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 3% 25%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 3% 25%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.blue-btn a {
  color: white;
  text-decoration: none;
  text-align: center;
  display: block;
  /* important */
}

.blue-btn,
.first-link {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}


.blue-btn {
  height: 64px;
  font: normal normal 700 1em/4em Arial, sans-serif;
  overflow: hidden;
  width: 200px;
  background-color: #3b5998;
  border-radius: 20px;
}

.blue-btn:hover {
  background-color: #003D60;

}

.blue-btn a:hover {
  text-decoration: none;
}

.first-link {
  margin-top: 0em;
}

.blue-btn:hover .first-link {
  margin-top: -4em;
}


#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down{
  0%{
    transform:translateY(10px);
  }
  100%{
    transform: translateY(-10px);
  }
}
@keyframes up-down{
  0% {
    transform: translateY(10px);
  }
  
  100% {
    transform: translateY(-10px);
  }
}

.text__justify p {
  margin: 0;
  text-align: justify;
}
.text__justify h2 {
  padding: 2rem;
}

.bg__image{
  background-image: url('./Images/b2.jpg'), linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7));
}

.jarallax {
  position: relative;
  z-index: 0;
}

.bg__dark {
  opacity: 25;
  background-color: #131022 !important;
}